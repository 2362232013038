import React from 'react';
import Button from '../../../atoms/button';
import { ethers } from 'ethers';
import {
  failedTransactionActions,
  formBlockExplorerLink
} from '../../../../helpers/txHelper';
import { CHAIN, NO_OF_BLOCK_CONFIRMATIONS } from '../../../../helpers/config';
import { genericErrorHandler, stringTruncate } from '../../../../helpers/utils';
import { useAppStore } from '../../../../lib/store';
import { displayToast } from '../../../molecules/toast';
import { ToastType } from '../../../molecules/toast/types';
import { Spinner } from '../../../atoms/spinner';
import { Icon } from '../../../atoms/icon';
import { Scope } from '@sentry/nextjs';
import { BigNumber } from '@ethersproject/bignumber';

const Submit = () => {
  const {
    metaMaskInfo,
    keplrInfo,
    fetchBalances,
    balances,
    txnInfo,
    tokenType,
    setTxnInfo,
    instances
  } = useAppStore();

  console.log(balances, 'balances');
  let enable: boolean;
  if (tokenType === 'stkATOM') {
    enable =
      metaMaskInfo.walletConnected &&
      (balances.stkATOM!.bondedLp.gt(ethers.utils.parseEther('0')) ||
        balances.stkATOM!.lpTokens.gt(ethers.utils.parseEther('0')));
  } else {
    enable =
      metaMaskInfo.walletConnected &&
      (balances!.stkXPRT!.bondedLp!.gt(ethers.utils.parseEther('0')) ||
        balances!.stkXPRT!.lpTokens!.gt(ethers.utils.parseEther('0')));
  }

  const approveTxnHandler = async () => {
    try {
      setTxnInfo({
        type: 'remove-liquidity',
        inProgress: true,
        status: 'pending'
      });
      const stakeLpContractAddress =
        CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!]['CONTRACT_ADDRESSES'][
          tokenType
        ].StakeLP;

      const unbondAmount: BigNumber = balances[tokenType]!.lpTokens;
      const tokenAbiInstance = instances.lpTokens[tokenType];
      const txn = await tokenAbiInstance.approve(
        stakeLpContractAddress,
        unbondAmount
      );

      await txn.wait(NO_OF_BLOCK_CONFIRMATIONS);
      displayToast(
        {
          message: (
            <>
              You approved the contract to send lp&nbsp;
              <a
                rel="noreferrer"
                className="flex items-center"
                target={'_blank'}
                href={formBlockExplorerLink(txn.hash)}
              >
                {stringTruncate(txn.hash)}
                <Icon iconName="arrow-redirect" viewClass="icon" />
              </a>
            </>
          )
        },
        ToastType.SUCCESS
      );
      // setTxnInfo({
      //   type: 'remove-liquidity',
      //   inProgress: false,
      //   status: 'success'
      // });
      await txnHandler();
    } catch (e: any) {
      const customScope = new Scope();
      customScope.setLevel('fatal');
      customScope.setTags({
        'Error approving bondLp': metaMaskInfo!.address
      });
      genericErrorHandler(e, customScope);
      setTxnInfo({
        type: 'remove-liquidity',
        inProgress: false,
        status: 'failed'
      });
      await failedTransactionActions('');
    }
  };

  const txnHandler = async () => {
    try {
      setTxnInfo({
        type: 'remove-liquidity',
        inProgress: true,
        status: 'pending'
      });

      let txn: any;

      if (tokenType === 'stkATOM') {
        txn = await instances.stakeLp[tokenType].removeLPToken(
          balances.stkATOM.lpTokens,
          '0x44017598f2AF1bD733F9D87b5017b4E7c1B28DDE' // stkATOM address
        );
      } else {
        txn = await instances.stakeLp[tokenType].removeLPToken(
          balances.stkXPRT.lpTokens,
          '0x45e007750Cc74B1D2b4DD7072230278d9602C499' //stkXPRT address
        );
      }
      displayToast(
        {
          message:
            'Waiting for transaction to be included in the block, ' +
            'Do not close or refresh. Txn will take 60 to 80 seconds'
        },
        ToastType.LOADING
      );
      await txn.wait(NO_OF_BLOCK_CONFIRMATIONS);
      displayToast(
        {
          message: (
            <>
              Successfully Remove Liquidity&nbsp;
              <a
                rel="noreferrer"
                className="flex items-center"
                target={'_blank'}
                href={formBlockExplorerLink(txn.hash)}
              >
                {stringTruncate(txn.hash)}
                <Icon iconName="arrow-redirect" viewClass="icon" />
              </a>
            </>
          )
        },
        ToastType.SUCCESS
      );
      setTxnInfo({
        type: 'remove-liquidity',
        inProgress: false,
        status: 'success'
      });
      await fetchBalances(instances, metaMaskInfo.address);
    } catch (e: any) {
      const customScope = new Scope();
      customScope.setLevel('fatal');
      customScope.setTags({
        'Error while remove liquidity txn': tokenType
      });
      genericErrorHandler(e, customScope);
      setTxnInfo({
        type: 'remove-liquidity',
        inProgress: false,
        status: 'failed'
      });
      await failedTransactionActions('');
    }
  };

  return (
    <div className="mt-6">
      <Button
        className="button w-full md:py-2 !py:2 md:text-sm"
        type="primary"
        size="medium"
        disabled={
          !enable || (txnInfo.type === 'remove-liquidity' && txnInfo.inProgress)
        }
        onClick={
          balances[tokenType]!.allowance.lt(balances[tokenType]!.lpTokens)
            ? approveTxnHandler
            : txnHandler
        }
        content={
          metaMaskInfo.walletConnected ? (
            txnInfo.type === 'remove-liquidity' && txnInfo.inProgress ? (
              <Spinner size={'medium'} />
            ) : (
              'Remove Liquidity'
            )
          ) : (
            'Connect Wallet'
          )
        }
      />
    </div>
  );
};

export default Submit;
