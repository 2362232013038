import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchInstances,
  fetchKeplrAddress,
  genericErrorHandler,
  stringTruncate
} from '../../../../helpers/utils';
import { ToastType } from '../../../molecules/toast/types';
import { displayToast } from '../../../molecules/toast';
import { CHAIN } from '../../../../helpers/config';
import { useAppStore } from '../../../../lib/store';
import Copy from '../../../molecules/copy';
import { Icon } from '../../../atoms/icon';
import { Scope } from '@sentry/nextjs';
import MetaMaskConnection from '../../wallet-connection';

const WalletConnection = () => {
  const {
    metaMaskInfo,
    connectMetaMask,
    connectKeplr,
    keplrInfo,
    fetchBalances,
    setContractInstances,
    instances
  } = useAppStore();

  useEffect(() => {
    // listen for account changes
    if (keplrInfo.persistenceAddress !== '') {
      window.addEventListener('keplr_keystorechange', async () => {
        await keplrConnect();
      });
    }
  }, [metaMaskInfo]);

  const keplrConnect = async () => {
    try {
      const pAddress = await fetchKeplrAddress('core-1');
      const cosmosAddress = await fetchKeplrAddress('cosmoshub-4');
      await connectKeplr(pAddress, cosmosAddress);
    } catch (e: any) {
      const customScope = new Scope();
      customScope.setLevel('fatal');
      customScope.setTags({
        'Error while connecting keplr': ''
      });
      genericErrorHandler(e, customScope);
      displayToast(
        {
          message: 'Error while Connecting to keplr'
        },
        ToastType.ERROR
      );
    }
  };

  return (
    <div className="flex justify-between">
      <MetaMaskConnection customClass={''} type={'migration'} />
      <div className={'flex mx-2 items-baseline pt-[30px]'}>
        <div
          className={`icon-box 
          rounded-full flex justify-center items-center border-2 border-solid border-[#272727]
          w-[48px] h-[48px] bg-[#1A1A1A] md:w-[28px] md:h-[28px]`}
        >
          <Icon iconName="arrow-right" viewClass=" !w-[14px] md:!w-[10px]" />
        </div>
      </div>
      <div className="w-[200px]">
        <p className="text-light-mid text-sm leading-normal text-center">
          Destination
        </p>
        <div
          className={`${
            keplrInfo.cosmosAddress !== ''
              ? 'border-[#00FFA3]'
              : 'border-[#1B1B1B]'
          } border border-solid bg-[#1B1B1B] mb-4 mt-2 flex items-center justify-center rounded-md py-3 px-8 chain-box `}
        >
          <img
            src={'/images/xprt.svg'}
            width={28}
            height={28}
            className="mr-2"
            alt="atom Logo"
          />
          <span className="text-light-emphasis text-lg font-medium leading-normal text-center">
            Persistence
          </span>
        </div>
        {keplrInfo.persistenceAddress !== '' ? (
          <div className="bg-[#1B1B1B] rounded-md px-4 py-1.5 flex items-center justify-center w-full">
            <img
              width={16}
              height={16}
              className="mr-2"
              src={'/images/keplr.svg'}
              alt="keplr"
            />
            <div className="text-[#47C28B] text-xs flex items-center">
              <span className="cursor-pointer mr-2">
                {stringTruncate(keplrInfo.persistenceAddress, 6)}
              </span>
              <Copy id={keplrInfo.persistenceAddress} truncate={true} />
            </div>
          </div>
        ) : (
          <button
            className="text-light-emphasis border-2 flex border-[#F7931A]
        rounded-md px-4 py-1.5 items-center justify-center hover:bg-red hover:text-[#2F2F2F] text-sm md:text-xsm  w-full"
            onClick={keplrConnect}
          >
            <img
              width={16}
              height={16}
              className="mr-2"
              src={'/images/keplr.svg'}
              alt="metamask"
            />
            Connect Wallet
          </button>
        )}
      </div>
    </div>
  );
};

export default WalletConnection;
