import React, { useState } from 'react';
import { Icon } from '../../atoms/icon';
import Dropdown from '../../molecules/dropdown';
import { TxnOption } from '../../../lib/slices/walletSlice';
import { useAppStore } from '../../../lib/store';
import TabItem from '../../molecules/tabs/tabItem';
import TabContent from '../../molecules/tabs/tabContent';
import MigrationContainer from './migration';
import RemoveLiquidity from './remove-liquidity';

interface OptionsList {
  name: TxnOption;
  iconName: string;
  label: string;
}

export const getLogoPath = (network: TxnOption) => {
  let iconName = '';
  switch (network) {
    case 'migration':
      iconName = 'wallet';
      break;
    case 'remove-liquidity':
      iconName = 'validators';
      break;
    default:
      iconName = 'wallet';
  }
  return iconName;
};

const TxnOptionDropdown = () => {
  const [show, setShow] = useState<boolean>(false);
  const { balances, txnType, setTxnType } = useAppStore();

  const list: OptionsList[] = [
    {
      name: 'migration',
      label: 'Migrate ERC20 Assets',
      iconName: 'wallet'
    },
    {
      name: 'remove-liquidity',
      label: 'Remove Liquidity',
      iconName: 'validators'
    }
  ];

  const selectedItem = list.find((item) => item.name === txnType);

  const tabHandler = (name: TxnOption) => {
    setTxnType(name);
    // setShow(false);
  };

  const dropCloseDownHandler = (value: boolean) => {
    setShow(value);
  };

  const tabItemClasses =
    'cursor-pointer w-full bg-tabHeader ' +
    'font-semibold text-lg leading-normal text-center' +
    ' text-light-mid flex-1 px-4 py-2 md:px-2 md:py-1.5 md:text-base';

  return (
    // <Dropdown
    //   className="text-light-high w-full mb-2"
    //   dropDownVariant="custom"
    //   closeDropdown={show}
    //   closeHandler={(value) => dropCloseDownHandler(value)}
    //   dropDownVariantBg="bg-black-700 text-[12px] text-light-high"
    //   dropdownLabel={
    //     <div className="flex items-center">
    //       <div
    //         className="rounded-full bg-[#F7931A] flex items-center
    //           justify-center w-[26px] h-[26px] mr-2"
    //       >
    //         <Icon
    //           iconName="wallet"
    //           viewClass={'fill-[#FFFFFF] !w-[14px] !h-[12px]'}
    //         />
    //       </div>
    //       <span className="text-base text-light-emphasis font-medium leading-normal md:text-xsm md:ml-2 capitalize">
    //         {selectedItem!.label}
    //       </span>
    //     </div>
    //   }
    //   dropDownButtonClass="text-[12px] text-light-high !py-4 bg-dark-700
    //      button md:text-sm w-full bg-[#151515] !px-6 !justify-between mb-1"
    //   dropdownType={'click'}
    //   staticBackDrop={false}
    //   dropDownIcon={true}
    //   dropDownContentClass="!bg-[#242424] drop-shadow-md round-md w-max py-0 md:p-0"
    // >
    //   <>
    //     {list.map((item, index) => (
    //       <div
    //         key={index}
    //         className={`px-6 py-4 flex items-center cursor-pointer text-dark-high whitespace-nowrap
    //         ${txnType === item.name ? 'bg-[#191919]' : ''}`}
    //         onClick={() => {
    //           dropDownHandler(item.name);
    //         }}
    //       >
    //         <div className={'flex-1'}>
    //           <div className="flex items-center">
    //             <div
    //               className="rounded-full bg-[#F7931A] flex items-center
    //                  justify-center w-[22px] h-[22px] mr-2"
    //             >
    //               <Icon
    //                 iconName={'wallet'}
    //                 viewClass={'fill-[#FFFFFF] !w-[14px] !h-[12px]'}
    //               />
    //             </div>
    //             <span className="text-sm text-light-emphasis leading-normal md:text-xsm md:ml-2 capitalize">
    //               {item.label}
    //             </span>
    //           </div>
    //         </div>
    //       </div>
    //     ))}
    //   </>
    // </Dropdown>
    <div>
      <ul className="tabsHeaderList flex flex-wrap mb-4">
        <TabItem
          id="migration"
          title={'Migration'}
          onClick={tabHandler}
          activeTab={txnType}
          className={tabItemClasses}
        />
        <TabItem
          id="remove-liquidity"
          title={'Remove Liquidity'}
          onClick={tabHandler}
          activeTab={txnType}
          className={tabItemClasses}
        />
      </ul>
      <div>
        <TabContent
          id="migration"
          activeTab={txnType}
          className="p-6 md:p-4 bg-tabContent rounded-md"
        >
          <MigrationContainer />
        </TabContent>
        <TabContent
          id="remove-liquidity"
          activeTab={txnType}
          className="p-6 md:p-4 bg-tabContent rounded-md"
        >
          <RemoveLiquidity />
        </TabContent>
      </div>
    </div>
  );
};

export default TxnOptionDropdown;
