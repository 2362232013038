import React from 'react';
import Header from '../molecules/Header/Header';
import BondLpModal from '../organisms/home/bondLpModal';
import TermsModal from '../organisms/termsModal';
import Footer from '../organisms/footer';

export const PageTemplate = ({
  children,
  className,
  title
}: {
  children: React.ReactNode;
  className: string;
  title: string;
}) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
      <TermsModal />
      <BondLpModal />
    </div>
  );
};
