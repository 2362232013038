import React from 'react';
import { useAppStore } from '../../../../lib/store';
import MetaMaskConnection from '../../wallet-connection';
import Submit from './Submit';
import TokenSelection from './tokenSelection';
import WalletBalance from './walletBalance';

const RemoveLiquidity = () => {
  const { balances, tokenType } = useAppStore();
  const stkAtomList = [
    {
      name: 'pATOM',
      balance: balances.stkATOM.pAtom,
      tooltip: true,
      tooltipText: (
        <>
          pATOM balances comprises of unclaimed <br /> staking rewards,
          unclaimed unbonded <br /> tokens and pATOM balance
        </>
      ),
      image: 'pAtom'
    },
    {
      name: 'LP Balance',
      balance: balances.stkATOM.lpTokens,
      tooltip: false,
      tooltipText: '',
      image: ''
    }
  ];

  const stkXprtList = [
    {
      name: 'pXPRT',
      balance: balances.stkXPRT.pXprt,
      tooltip: true,
      tooltipText: (
        <>
          pXPRT balances comprises of unclaimed <br /> staking rewards,
          unclaimed unbonded <br /> tokens and pXPRT balance
        </>
      ),
      image: 'pxprt'
    },
    {
      name: 'LP Balance',
      balance: balances.stkXPRT.lpTokens,
      tooltip: false,
      tooltipText: <>LP Balance</>,
      image: ''
    }
  ];

  return (
    <div className={`w-full bg-bg_bubble bg-no-repeat m-auto pb-10`}>
      <div className="bg-[#17171799] bg-no-repeat p-6 rounded-md">
        <h1 className="text-xl text-light-high font-semibold leading-normal text-center mb-6">
          Remove ETH liquidity from {tokenType} SushiSwap Pool
        </h1>

        <div className={'mx-auto w-[250px]'}>
          <MetaMaskConnection
            customClass={'!w-full'}
            type={'remove-liquidity'}
          />
        </div>
        <WalletBalance />

        <Submit />
      </div>
    </div>
  );
};

export default RemoveLiquidity;
