import React from 'react';
import { useAppStore } from '../../../../lib/store';
import {
  stringTruncate,
  truncateToFixedDecimalPlaces
} from '../../../../helpers/utils';
import Copy from '../../../molecules/copy';

const MigratedBalance = () => {
  const { metaMaskInfo, balances, stkAtomCvalue, keplrInfo, tokenType } =
    useAppStore();

  return (
    <div
      className={`mt-3 ${
        metaMaskInfo.walletConnected && keplrInfo.cosmosAddress
          ? 'visible'
          : 'hidden'
      }`}
    >
      <p className="text-light-emphasis text-base font-medium text-center mb-2">
        After Migration you will get
      </p>
      <div
        className="bg-black-700 flex items-center justify-center rounded-md px-6
         py-3 border border-[#F7931A] shadow-[0px_0px_29px_rgba(199,50,0,0.22)]"
      >
        <div className="flex-1">
          {tokenType === 'stkATOM' ? (
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img
                    width={20}
                    height={20}
                    className="mr-1"
                    src={'/images/stkATOM.svg'}
                    alt="stkATOM logo"
                  />
                  <div className="mx-2 flex items-center text-light-low text-sm">
                    stkATOM
                    <div className="text-[#47C28B] text-xs flex items-center mb-1">
                      <span className="cursor-pointer mr-2 ml-1">
                        {stringTruncate(keplrInfo.persistenceAddress, 6)}
                      </span>
                      <Copy
                        id={keplrInfo.persistenceAddress}
                        truncate={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <p className="pb-2 text-light-emphasis font-semibold text-sm">
                    {truncateToFixedDecimalPlaces(
                      (Number(balances[tokenType].stkAtom) +
                        Number(balances[tokenType].pAtom)) *
                        stkAtomCvalue,
                      6
                    )}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img
                    width={20}
                    height={20}
                    className="mr-1"
                    src={'/images/xprt.svg'}
                    alt="stkATOM logo"
                  />
                  <div className="mx-2 flex items-center text-light-low text-sm">
                    XPRT
                    <div className="text-[#47C28B] text-xs flex items-center mb-1">
                      <span className="cursor-pointer mr-2 ml-1">
                        {stringTruncate(keplrInfo.persistenceAddress, 6)}
                      </span>
                      <Copy id={keplrInfo.persistenceAddress} truncate={true} />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <p className="pb-2 text-light-emphasis font-semibold text-sm">
                    {truncateToFixedDecimalPlaces(
                      Number(balances[tokenType].stkXprt) +
                        Number(balances[tokenType].pXprt),
                      6
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MigratedBalance;
