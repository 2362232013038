import type { NextPage } from 'next';
import { PageTemplate } from '../components/templates';
import TxnContainer from '../components/organisms/home';

const Home: NextPage = () => {
  return (
    <PageTemplate className="migration" title="migration">
      <TxnContainer />
    </PageTemplate>
  );
};

export default Home;
