import React from 'react';
import Button from '../../../atoms/button';
import { ethers } from 'ethers';
import {
  failedTransactionActions,
  formBlockExplorerLink
} from '../../../../helpers/txHelper';
import {
  CHAIN,
  MIGRATION_ADMIN,
  NO_OF_BLOCK_CONFIRMATIONS
} from '../../../../helpers/config';
import {
  genericErrorHandler,
  getAbiJson,
  stringTruncate
} from '../../../../helpers/utils';
import { useAppStore } from '../../../../lib/store';
import { displayToast } from '../../../molecules/toast';
import { ToastType } from '../../../molecules/toast/types';
import { Spinner } from '../../../atoms/spinner';
import { Icon } from '../../../atoms/icon';
import { Scope } from '@sentry/nextjs';

const MigrationSubmit = () => {
  const {
    metaMaskInfo,
    keplrInfo,
    fetchBalances,
    balances,
    txnInfo,
    tokenType,
    setTxnInfo,
    instances
  } = useAppStore();

  let enable = false;
  if (tokenType === 'stkATOM') {
    enable =
      metaMaskInfo.walletConnected &&
      (balances.stkATOM.stkAtom! > 0 || balances.stkATOM.pAtom! > 0) &&
      keplrInfo.cosmosAddress !== '';
  } else {
    enable =
      metaMaskInfo.walletConnected &&
      (balances.stkXPRT.stkXprt! > 0 || balances.stkXPRT.pXprt! > 0) &&
      keplrInfo.cosmosAddress !== '';
  }

  const handlerMigrate = async () => {
    try {
      setTxnInfo({
        type: 'migrate',
        inProgress: true,
        status: 'pending'
      });

      let txn: any;

      if (tokenType === 'stkATOM') {
        txn = await instances.migrationAdmin[tokenType].migrate(
          keplrInfo.cosmosAddress,
          keplrInfo.persistenceAddress
        );
      } else {
        txn = await instances.migrationAdmin[tokenType].migrate(
          keplrInfo.persistenceAddress
        );
      }
      displayToast(
        {
          message:
            'Waiting for transaction to be included in the block, ' +
            'Do not close or refresh. Migration will take 60 to 80 seconds'
        },
        ToastType.LOADING
      );
      await txn.wait(NO_OF_BLOCK_CONFIRMATIONS);
      displayToast(
        {
          message: (
            <>
              Successfully Migrated&nbsp;
              <a
                rel="noreferrer"
                className="flex items-center"
                target={'_blank'}
                href={formBlockExplorerLink(txn.hash)}
              >
                {stringTruncate(txn.hash)}
                <Icon iconName="arrow-redirect" viewClass="icon" />
              </a>
            </>
          )
        },
        ToastType.SUCCESS
      );
      setTxnInfo({
        type: 'migrate',
        inProgress: false,
        status: 'success'
      });
      await fetchBalances(instances, metaMaskInfo.address);
    } catch (e: any) {
      const customScope = new Scope();
      customScope.setLevel('fatal');
      customScope.setTags({
        'Error while migration txn': tokenType
      });
      genericErrorHandler(e, customScope);
      setTxnInfo({
        type: 'migrate',
        inProgress: false,
        status: 'failed'
      });
      await failedTransactionActions('');
    }
  };

  return (
    <div className="mt-6">
      <Button
        className="button w-full md:py-2 !py:2 md:text-sm"
        type="primary"
        size="medium"
        disabled={!enable || (txnInfo.type === 'migrate' && txnInfo.inProgress)}
        onClick={handlerMigrate}
        content={
          metaMaskInfo.walletConnected && keplrInfo.cosmosAddress !== '' ? (
            txnInfo.type === 'migrate' && txnInfo.inProgress ? (
              <Spinner size={'medium'} />
            ) : (
              'Migrate'
            )
          ) : (
            'Connect wallets to Migrate'
          )
        }
      />
    </div>
  );
};

export default MigrationSubmit;
