import React from 'react';
import { Icon } from '../../atoms/icon';

const Footer = () => {
  const socialList = [
    {
      url: 'https://twitter.com/pStakeFinance',
      iconName: 'twitter-logo',
      tooltip: 'Twitter'
    },
    {
      url: 'https://t.me/pstakefinancechat',
      iconName: 'telegram-plane',
      tooltip: 'Telegram'
    },
    {
      url: 'https://blog.pstake.finance/',
      iconName: 'medium-m',
      tooltip: 'Medium'
    }
  ];

  return (
    <div className="flex items-center justify-center py-4 px-8">
      <div className={'flex items-center justify-center flex-1'}>
        <div className={`socialLinks flex`}>
          {socialList.map((item, index) => (
            <a
              key={item.iconName}
              href={item.url}
              rel="noopener noreferrer"
              className="mr-2.5"
              target="_blank"
            >
              <Icon
                viewClass="socialIcon fill-[#a6a6a6] !w-[14px] !h-[14px]"
                iconName={item.iconName}
              />
            </a>
          ))}
        </div>
        <a
          href={'https://pstake.finance/terms'}
          rel="noopener noreferrer"
          target="_blank"
        >
          <p className="text-[#676767] text-sm font-medium">Terms of Use</p>
        </a>
        <div className="w-1 h-1 bg-[#ffffff] rounded-full mx-1.5" />
        <a
          href={'https://pstake.finance/privacy'}
          rel="noopener noreferrer"
          target="_blank"
        >
          <p className="text-[#676767] text-sm font-medium">Privacy Policy</p>
        </a>
      </div>
    </div>
  );
};

export default Footer;
