import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchInstances,
  fetchKeplrAddress,
  genericErrorHandler,
  stringTruncate
} from '../../../helpers/utils';
import { ToastType } from '../../molecules/toast/types';
import { displayToast } from '../../molecules/toast';
import { CHAIN } from '../../../helpers/config';
import { useAppStore } from '../../../lib/store';
import Copy from '../../molecules/copy';
import { Icon } from '../../atoms/icon';
import { Scope } from '@sentry/nextjs';
import { TxnOption } from '../../../lib/slices/walletSlice';

export const addTestnetNetwork = async (provider: any) => {
  const providerE: any = provider;
  try {
    await providerE.request({
      method: 'wallet_switchEthereumChain',
      params: [
        { chainId: CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!].networkIdHex }
      ] // Hexadecimal version of 80001, prefixed with 0x
    });
    return true;
  } catch (error: any) {
    return false;
  }
};

interface Props {
  customClass: string;
  type: TxnOption;
}
const MetaMaskConnection = ({ customClass, type }: Props) => {
  const {
    metaMaskInfo,
    connectMetaMask,
    connectKeplr,
    keplrInfo,
    fetchBalances,
    setContractInstances,
    instances
  } = useAppStore();

  useEffect(() => {
    // listen for account changes
    if (metaMaskInfo.walletConnected) {
      let metamaskProvider: any;
      if (window.ethereum?.providers) {
        metamaskProvider = window.ethereum?.providers.find((item: any) => {
          return item && item.isMetaMask;
        });
      } else {
        metamaskProvider = window.ethereum;
      }

      metamaskProvider?.on('accountsChanged', function (accounts: any) {
        metamaskHandler();
      });

      metamaskProvider?.on('chainChanged', function (chainId: string) {
        if (
          chainId !== CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!].networkIdHex
        ) {
          alert('Network changed please connect again');
          window.location.reload();
        }
      });
    }
  }, [metaMaskInfo]);

  const metamaskHandler = async () => {
    try {
      const ethereum = window.ethereum;
      if (!ethereum || !ethereum.on) {
        displayToast(
          {
            message: 'Metamask not installed'
          },
          ToastType.ERROR
        );
        return;
      }
      if (
        Number(ethereum.networkVersion) !==
        CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!].networkID
      ) {
        const addedBSC = await addTestnetNetwork(ethereum);
        if (!addedBSC) {
          displayToast(
            {
              message: 'Error while Switching network'
            },
            ToastType.ERROR
          );
          return;
        }
        const newInstances = fetchInstances();
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts'
        });
        setContractInstances(newInstances);
        if (accounts.length !== 0) {
          const account: string = '0x062d3a310386c91b51ab0eeb3a3edba5028deac1';
          await connectMetaMask({
            walletConnected: true,
            address: account
          });
          await fetchBalances(newInstances, account);
        } else {
          throw new Error('account not found');
        }
      } else {
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts'
        });
        if (accounts.length !== 0) {
          const account: string = accounts[0];
          await connectMetaMask({
            walletConnected: true,
            address: account
          });
          await fetchBalances(instances, account);
        } else {
          throw new Error('account not found');
        }
      }
    } catch (e: any) {
      const customScope = new Scope();
      customScope.setLevel('fatal');
      customScope.setTags({
        'Error while connecting metamask txn': ''
      });
      genericErrorHandler(e, customScope);
      displayToast(
        {
          message: e.message
        },
        ToastType.ERROR
      );
    }
  };

  return (
    <div className={`w-[200px] ${customClass}`}>
      {type === 'migration' ? (
        <p className="text-light-mid text-sm leading-normal text-center">
          Source
        </p>
      ) : (
        ''
      )}
      <div
        className={`${
          metaMaskInfo.walletConnected ? 'border-[#00FFA3]' : 'border-[#1B1B1B]'
        } border border-solid bg-[#1B1B1B] mt-2 mb-4 flex items-center justify-center rounded-md py-3 px-8`}
      >
        <img
          src={'/images/eth_logo.svg'}
          width={26}
          height={26}
          className="mr-2"
          alt="ETH Logo"
        />
        <span className="text-light-emphasis text-lg font-medium leading-normal text-center">
          Ethereum
        </span>
      </div>
      {metaMaskInfo.walletConnected ? (
        <div className="bg-[#1B1B1B] rounded-md py-1.5 flex items-center justify-center px-4 py-1.5 w-full">
          <img
            width={16}
            height={16}
            className="mr-2 pt-0"
            src={'/images/metamask.svg'}
            alt="metamask"
          />
          <div className="text-[#47C28B] text-xs flex items-center">
            <span className="cursor-pointer mr-2">
              {stringTruncate(metaMaskInfo.address, 6)}
            </span>
            <Copy id={metaMaskInfo.address} truncate={true} />
          </div>
        </div>
      ) : (
        <button
          className="text-light-emphasis border-2 flex items-center border-[#F7931A]
         rounded-md px-4 py-1.5 items-center justify-center hover:bg-red hover:text-[#2F2F2F] md:text-xsm w-full"
          onClick={metamaskHandler}
        >
          <img
            width={16}
            height={16}
            className="mr-2"
            src={'/images/metamask.svg'}
            alt="metamask"
          />
          Connect Wallet
        </button>
      )}
    </div>
  );
};

export default MetaMaskConnection;
