import React from 'react';
import Modal from '../../../molecules/modal';
import { Icon } from '../../../atoms/icon';
import { Spinner } from '../../../atoms/spinner';
import Button from '../../../atoms/button';
import { useAppStore } from '../../../../lib/store';

const TransactionIcon = (
  stepNumber: number,
  value: number,
  txFailed: string
) => {
  return stepNumber < value ? (
    <Icon
      iconName="success-full-disable"
      viewClass="icon-arrow !w-[1.5rem] !h-[1.5rem] md:!w-[1.3rem] md:!h-[1.3rem]"
    />
  ) : stepNumber === value ? (
    txFailed === 'failed' ? (
      <Icon
        iconName="error"
        viewClass="icon-error !w-[1.5rem] !h-[1.5rem] md:!w-[1.3rem] md:!h-[1.3rem]"
      />
    ) : (
      <Spinner size="medium" />
    )
  ) : (
    <Icon
      iconName="success-full"
      viewClass="icon-arrow !w-[1.5rem] !h-[1.5rem] md:!w-[1.3rem] md:!h-[1.3rem]"
    />
  );
};

const BondLpModal = () => {
  const { txnInfo, setBondTxnInfo, setTxnInfo, bondTxn } = useAppStore();

  const handleClose = () => {
    setBondTxnInfo({
      stepNumber: 0,
      showModal: false
    });
    setTxnInfo({
      type: '',
      inProgress: false,
      status: ''
    });
  };

  return (
    <Modal
      show={bondTxn.showModal}
      onClose={handleClose}
      header=""
      className="bondModal"
      staticBackDrop={true}
      closeButton={true}
    >
      <p
        className="text-light-high text-center font-semibold text-lg leading normal px-8 pt-8 md:px-6 md:pt-6
       md:text-base"
      >
        Bond LP Tokens
      </p>
      <div className={`px-8 pt-8 md:px-7 md:pt-6 m-0`}>
        <div className="mb-8 md:mb-6">
          <div className="flex items-center mb-5 md:mb-3">
            <div className="mr-3">
              {TransactionIcon(bondTxn.stepNumber, 1, txnInfo.status)}
            </div>
            <p
              className={`${
                bondTxn.stepNumber >= 1
                  ? 'text-light-emphasis'
                  : 'text-light-low'
              } text-base font-normal`}
            >
              {bondTxn.stepNumber > 1
                ? 'You approved the contract to send lp'
                : 'Approve Transfer tokens to StakeLP contract'}
            </p>
          </div>
          <div className="flex items-center mb-5 md:mb-3">
            <div className="mr-3">
              {TransactionIcon(bondTxn.stepNumber, 2, txnInfo.status)}
            </div>
            <p
              className={`${
                bondTxn.stepNumber >= 2
                  ? 'text-light-emphasis'
                  : 'text-light-low'
              } text-base font-normal`}
            >
              Approve Transaction to bond LP Tokens
            </p>
          </div>
        </div>

        {txnInfo.status === 'failed' ? (
          <p className="text-base text-light-high text-center font-semibold mb-4 md:mb-3 md:text-sm">
            Transaction could not be completed.
          </p>
        ) : null}
        {bondTxn.stepNumber === 3 && (
          <p className="text-base text-light-high text-center font-semibold mb-4 md:text-sm">
            You&apos;ve successfully bonded Lp tokens
          </p>
        )}
        {txnInfo.status === 'failed' || bondTxn.stepNumber === 3 ? (
          <Button
            className="button w-full md:py-2 md:text-sm flex items-center
            justify-center w-[350px]  md:w-[200px]  mx-auto mb-10 md:mb-7"
            type="primary"
            size="medium"
            content="Close"
            onClick={handleClose}
          />
        ) : null}

        {txnInfo.inProgress ? (
          <div className="pb-4 flex justify-center">
            <img src={'/images/bulb.svg'} width={16} height={16} alt="bulb" />
            <p className="text-[#ECECEC] text-sm font-normal pl-3">
              Do not refresh the page or close this window. Transaction will
              take 60 to 80 seconds... 
            </p>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default BondLpModal;
