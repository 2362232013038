import React, { useState } from 'react';
import { useWindowSize } from '../../../../customHooks/useWindowSize';
import Dropdown from '../../../molecules/dropdown';
import { tokenType } from '../../../../lib/slices/walletSlice';
import { useAppStore } from '../../../../lib/store';

interface tokenList {
  name: tokenType;
  image: string;
}

const TokenSelection = () => {
  const { tokenType, setTokenType, balances } = useAppStore();
  const [dropDownItem, setDropdownItem] = useState<tokenList>({
    name: 'stkATOM',
    image: 'stkatom_erc20'
  });
  const [show, setShow] = useState<boolean>(false);
  const { isMobile } = useWindowSize();

  const list: tokenList[] = [
    {
      name: 'stkATOM',
      image: 'stkatom_erc20'
    },
    {
      name: 'stkXPRT',
      image: 'stkXPRT'
    }
  ];

  const dropDownHandler = async (type: tokenType) => {
    const token: tokenList | undefined = list.find(
      (item) => item.name === type
    );
    setShow(false);
    setDropdownItem(token!);
    setTokenType(type);
  };

  const dropCloseDownHandler = (value: boolean) => {
    setShow(value);
  };

  const token: tokenList | undefined = list.find(
    (item) => item.name === tokenType
  );

  return (
    <div className="mb-3 flex justify-between items-center">
      <Dropdown
        className="text-light-high"
        dropDownVariant="custom"
        closeDropdown={show}
        closeHandler={(value) => dropCloseDownHandler(value)}
        dropDownVariantBg="bg-black-mid text-[12px] text-light-high"
        dropdownLabel={
          <div className="flex items-center">
            <img
              width={20}
              height={20}
              className="mr-2"
              src={`/images/${token!.image}.svg`}
              alt="stkATOM logo"
            />
            <span className="text-sm text-light-emphasis font-medium leading-normal md:text-xsm md:ml-2">
              {token!.name} (ERC20)
            </span>
          </div>
        }
        dropDownButtonClass="!py-2.5"
        dropdownType={'click'}
        staticBackDrop={false}
        dropDownIcon={true}
        dropDownContentClass="!bg-[#282828] drop-shadow-md round-md
                       py-1 md:p-0"
      >
        {list.map((item, index) => (
          <div key={index}>
            {item.name !== tokenType ? (
              <div
                className="px-4 py-2 flex items-center md:py-3
                        hover:cursor-pointer hover:bg-[#383838] text-dark-high whitespace-nowrap"
                key={1}
                onClick={() => {
                  dropDownHandler(item.name);
                }}
              >
                <div className="flex items-center">
                  <img
                    width={20}
                    height={20}
                    className="mr-2"
                    src={`/images/${item.image}.svg`}
                    alt="stkATOM logo"
                  />
                  <span className="text-sm text-light-emphasis font-medium leading-normal md:text-xsm md:ml-2">
                    {item.name}(ERC20)
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </Dropdown>
    </div>
  );
};

export default TokenSelection;
