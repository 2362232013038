import React from 'react';
import { displayToast } from '../components/molecules/toast';
import { ToastType } from '../components/molecules/toast/types';
import { CHAIN } from './config';

export const formBlockExplorerLink = (txnHash: string) => {
  const explorerLink =
    CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!].etherscanLink;
  if (txnHash) return `${explorerLink}/${txnHash}`;
  return '';
};

export const failedTransactionActions = (txnHash: string) => {
  displayToast(
    {
      message: `This transaction could not be completed${
        txnHash ? `: ${txnHash}` : ''
      }`
    },
    ToastType.ERROR
  );
};
