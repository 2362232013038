import React, { useState } from 'react';
import { useAppStore } from '../../../lib/store';
import TxnOptionDropdown from './txn-options';

const TxnContainer = () => {
  const { txnType } = useAppStore();

  return (
    <div className={`max-w-[616px] md:max-w-[500px] m-auto`}>
      <TxnOptionDropdown />
      {/*{txnType === 'migration' ? <MigrationContainer /> : <RemoveLiquidity />}*/}
    </div>
  );
};

export default TxnContainer;
