import React from 'react';
import WalletConnection from './walletConnection';
import WalletBalance from './walletBalance';
import MigrationSubmit from './MigrationSubmit';
import MigratedBalance from './MigratatedBalanceInfo';
import TokenSelection from './tokenSelection';
import { useAppStore } from '../../../../lib/store';

const MigrationContainer = () => {
  const { balances, tokenType } = useAppStore();
  const stkAtomList = [
    {
      name: 'pATOM',
      balance: balances.stkATOM.pAtom,
      tooltip: true,
      tooltipText: (
        <>
          pATOM balances comprises of unclaimed <br /> staking rewards,
          unclaimed unbonded <br /> tokens and pATOM balance
        </>
      ),
      image: 'pAtom'
    },
    {
      name: 'LP Balance',
      balance: balances.stkATOM.lpTokens,
      tooltip: false,
      tooltipText: '',
      image: ''
    }
  ];

  const stkXprtList = [
    {
      name: 'pXPRT',
      balance: balances.stkXPRT.pXprt,
      tooltip: true,
      tooltipText: (
        <>
          pXPRT balances comprises of unclaimed <br /> staking rewards,
          unclaimed unbonded <br /> tokens and pXPRT balance
        </>
      ),
      image: 'pxprt'
    },
    {
      name: 'LP Balance',
      balance: balances.stkXPRT.lpTokens,
      tooltip: false,
      tooltipText: <>LP Balance</>,
      image: ''
    }
  ];

  return (
    <div className={`w-full bg-bg_bubble bg-no-repeat m-auto pb-10`}>
      <div className="bg-[#17171799] bg-no-repeat p-6 rounded-md">
        <h1 className="text-xl text-light-high font-semibold leading-normal text-center mb-2">
          Migrate {tokenType}
        </h1>
        <p className="text-sm text-light-mid leading-normal text-center mb-4">
          {tokenType === 'stkATOM'
            ? 'Convert ERC-20 stkATOM to stkATOM on the Persistence Chain'
            : 'Convert ERC-20 stkXPRT to XPRT on the Persistence Chain'}
        </p>
        <WalletConnection />
        <WalletBalance
          list={tokenType === 'stkATOM' ? stkAtomList : stkXprtList}
        />
        <MigratedBalance />
        <MigrationSubmit />
      </div>
    </div>
  );
};

export default MigrationContainer;
