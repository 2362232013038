import React from 'react';
import Tooltip from 'rc-tooltip';
import { Icon } from '../../../atoms/icon';
import Button from '../../../atoms/button';
import { useAppStore } from '../../../../lib/store';
import { Spinner } from '../../../atoms/spinner';
import { CHAIN, NO_OF_BLOCK_CONFIRMATIONS } from '../../../../helpers/config';
import {
  genericErrorHandler,
  stringTruncate,
  unDecimalize
} from '../../../../helpers/utils';
import { ethers, utils } from 'ethers';
import {
  failedTransactionActions,
  formBlockExplorerLink
} from '../../../../helpers/txHelper';
import { displayToast } from '../../../molecules/toast';
import { ToastType } from '../../../molecules/toast/types';
import TokenSelection from './tokenSelection';
import { Scope } from '@sentry/nextjs';

const WalletBalance = ({ list }: any) => {
  const {
    metaMaskInfo,
    balances,
    fetchBalances,
    txnInfo,
    setTxnInfo,
    setBondTxnInfo,
    tokenType,
    instances
  } = useAppStore();

  const approveTxnHandler = async () => {
    try {
      setBondTxnInfo({
        stepNumber: 1,
        showModal: true
      });
      setTxnInfo({
        type: 'unbondLp',
        inProgress: true,
        status: 'pending'
      });
      const stakeLpContractAddress =
        CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!]['CONTRACT_ADDRESSES'][
          tokenType
        ].StakeLP;

      const unbondAmount = balances[tokenType]!.lpTokens;

      const tokenAbiInstance = instances.lpTokens[tokenType];

      const txn = await tokenAbiInstance.approve(
        stakeLpContractAddress,
        unbondAmount
      );
      await txn.wait(NO_OF_BLOCK_CONFIRMATIONS);
      displayToast(
        {
          message: (
            <>
              You approved the contract to send lp&nbsp;
              <a
                rel="noreferrer"
                className="flex items-center"
                target={'_blank'}
                href={formBlockExplorerLink(txn.hash)}
              >
                {stringTruncate(txn.hash)}
                <Icon iconName="arrow-redirect" viewClass="icon" />
              </a>
            </>
          )
        },
        ToastType.SUCCESS
      );
      setTxnInfo({
        type: 'unbondLp',
        inProgress: false,
        status: 'success'
      });
      await bondLpHandler();
    } catch (e: any) {
      const customScope = new Scope();
      customScope.setLevel('fatal');
      customScope.setTags({
        'Error approving bondLp': metaMaskInfo!.address
      });
      genericErrorHandler(e, customScope);
      setTxnInfo({
        type: 'unbondLp',
        inProgress: false,
        status: 'failed'
      });
      await failedTransactionActions('');
    }
  };

  const bondLpHandler = async () => {
    try {
      setBondTxnInfo({
        stepNumber: 2,
        showModal: true
      });
      const holderAddress =
        CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!]['CONTRACT_ADDRESSES'][
          tokenType
        ].StakeLpHolderAddress;

      const lpContractAddress =
        CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!]['CONTRACT_ADDRESSES'][
          tokenType
        ].TokenABI;

      const stakeLpInstance = instances.stakeLp[tokenType];

      setTxnInfo({
        type: 'unbondLp',
        inProgress: true,
        status: 'pending'
      });

      const unbondAmount = balances[tokenType]!.lpTokens;
      const txn = await stakeLpInstance.addLiquidity(
        holderAddress,
        lpContractAddress,
        unbondAmount
      );

      await txn.wait(NO_OF_BLOCK_CONFIRMATIONS);

      displayToast(
        {
          message: (
            <>
              Successfully Transferred lp tokens&nbsp;
              <a
                rel="noreferrer"
                className="flex items-center"
                target={'_blank'}
                href={formBlockExplorerLink(txn.hash)}
              >
                {stringTruncate(txn.hash)}
                <Icon iconName="arrow-redirect" viewClass="icon" />
              </a>
            </>
          )
        },
        ToastType.SUCCESS
      );
      setBondTxnInfo({
        stepNumber: 3,
        showModal: true
      });
      setTxnInfo({
        type: 'unbondLp',
        inProgress: false,
        status: 'success'
      });
      await fetchBalances(instances, metaMaskInfo.address);
    } catch (e: any) {
      const customScope = new Scope();
      customScope.setLevel('fatal');
      customScope.setTags({
        'Error making bondLp txn': metaMaskInfo!.address
      });
      genericErrorHandler(e, customScope);
      setTxnInfo({
        type: 'unbondLp',
        inProgress: false,
        status: 'failed'
      });
      await failedTransactionActions('');
    }
  };

  return (
    <div className="mt-3">
      <p className="text-light-emphasis text-base font-medium text-center mb-2">
        Your Balances
      </p>
      <div className="bg-black-700 flex min-h-[140px] items-center justify-center rounded-md px-6 py-3">
        {metaMaskInfo.walletConnected ? (
          <div className="flex-1">
            <TokenSelection />
            {list.map((item: any, index: number) =>
              item.name !== 'LP Balance' ? (
                <div
                  className="flex justify-between items-center mb-0 mt-2"
                  key={index}
                >
                  <div className="flex items-center px-4">
                    <img
                      width={20}
                      height={20}
                      className="mr-1"
                      src={`/images/${item.image}.svg`}
                      alt="stkATOM logo"
                    />
                    <p className="mx-2 text-light-low text-sm">{item.name}</p>
                    {item.tooltip ? (
                      <Tooltip
                        placement="bottom"
                        overlay={
                          <span className="text-center block">
                            {item.tooltipText}
                          </span>
                        }
                      >
                        <button className="icon-button px-1">
                          <Icon viewClass="arrow-right" iconName="info" />
                        </button>
                      </Tooltip>
                    ) : null}
                  </div>
                  <div className="flex justify-end">
                    <p className="pb-2 text-light-emphasis font-semibold text-sm">
                      {item.balance}
                    </p>
                  </div>
                </div>
              ) : null
            )}
          </div>
        ) : (
          <p className="text-[#393939] text-xl font-semibold text-center">
            Connect to view your Holdings
          </p>
        )}
      </div>
    </div>
  );
};

export default WalletBalance;
