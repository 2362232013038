import React from 'react';
import { useAppStore } from '../../../../lib/store';
import { ethers, utils } from 'ethers';
import TokenSelection from './tokenSelection';

const WalletBalance = () => {
  const {
    metaMaskInfo,
    balances,
    fetchBalances,
    txnInfo,
    setTxnInfo,
    setBondTxnInfo,
    tokenType,
    instances
  } = useAppStore();

  return (
    <div className="mt-3">
      <p className="text-light-emphasis text-base font-medium text-center mb-2">
        Your Balances
      </p>
      <div className="bg-black-700 flex min-h-[140px] items-center justify-center rounded-md px-6 py-3 mb-4">
        {metaMaskInfo.walletConnected ? (
          <div className="flex-1">
            <TokenSelection />
            <div className="flex justify-between items-center pt-1.5 pb-3">
              <div className="flex items-center">
                <p className="mx-2 text-light-low text-sm">Bonded LP Tokens</p>
              </div>
              <div className="flex items-center">
                <p className="pb-2 text-light-emphasis font-semibold text-sm">
                  {Number(utils.formatEther(balances[tokenType]!.bondedLp))}
                </p>
              </div>
            </div>
            <div className="flex justify-between items-center border-t border-[#2B2B2B] pt-3">
              <div className="flex items-center">
                <p className="mx-2 text-light-low text-sm">
                  Un-Bonded LP Tokens
                </p>
              </div>
              <div className="flex items-center">
                <p className="pb-2 text-light-emphasis font-semibold text-sm">
                  {Number(utils.formatEther(balances[tokenType]!.lpTokens))}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-[#393939] text-xl font-semibold text-center">
            Connect to view your Holdings
          </p>
        )}
      </div>

      <p className="text-light-emphasis text-base font-medium text-center mb-2">
        You will get
      </p>
      <div
        className="bg-black-700 flex items-center justify-center rounded-md px-6
         py-3 border border-[#F7931A] shadow-[0px_0px_29px_rgba(199,50,0,0.22)]"
      >
        <div className="flex-1">
          <div className="flex justify-between items-center my-2">
            <div className="flex items-center">
              <img
                width={20}
                height={20}
                className="mr-1"
                src={'/images/eth_logo.svg'}
                alt="ETH logo"
              />
              <div className="mx-2 flex items-center text-light-low text-sm">
                WETH
              </div>
            </div>
            <div className="flex justify-end max-w-[300px]">
              <p className="text-light-low text-sm text-right">
              {Number(utils.formatEther(balances[tokenType]!.ethShares))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletBalance;
