import React, { useState } from 'react';
import Link from 'next/link';
import Styles from './styles.module.css';
import { Icon } from '../../atoms/icon';
import { useWindowSize } from '../../../customHooks/useWindowSize';
import GeofenceNotice from '../geofence-banner';

const Header = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { isMobile } = useWindowSize();

  const headerList = [
    {
      name: 'persistence.one',
      url: 'https://persistence.one/'
    },
    {
      name: 'pstake.finance',
      url: 'https://pstake.finance/'
    }
  ];

  return (
    <div>
      <GeofenceNotice />
      <nav className="px-8 py-6 rounded">
        <div className="flex flex-wrap justify-between items-center mx-auto">
          <Link href="/" passHref className="flex items-center">
            <div className={'flex items-center'}>
              <img
                src={'/images/logo.svg'}
                className="max-w-[130px]"
                alt={'stakeIcon'}
              />
              <span
                className="text-sm font-light text-light-full ml-2 pl-3 md:text-sm md:ml-1 border-l border-[#4A4A4A]
            "
              >
                Migration Utility
              </span>
            </div>
          </Link>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="outline-none inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg
           -md:hidden focus:outline-none"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            <span className="sr-only">Open main menu</span>
            <Icon iconName={'menu'} viewClass={'w-[20px]'} />
          </button>
          <div
            className={`${
              isNavExpanded ? 'block' : 'hidden'
            } -md:block w-full -md:w-auto`}
            id="navbar-default"
          >
            <ul className="flex flex-col mt-4 -md:flex-row -md:space-x-8 -md:mt-0">
              {headerList.map((item, index) => (
                <li className="text-light-high font-medium text-sm" key={index}>
                  <a
                    href={item.url}
                    target={'_blank'}
                    rel="noreferrer"
                    className={`${Styles.navBarLink} block py-2 pr-0 pl-3 text-white`}
                    aria-current="page"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
